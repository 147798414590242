//Specific JS for this skin
require(['jquery'], function ($) {
	// This is stuff added by Bradly during design
	let footerLogoDesc = $('.footer-logo-desc p').html();
	$('.footer .module-logo').append('<p style="color:#fff;text-align:center;font-size: 1.3em;text-transform: uppercase;margin-top: 10px;">' + footerLogoDesc + '</p>');
	// Default stuff below
    $('#mini-cart .hide-below-960').html("<i class='fa fa-shopping-cart'></i>");
    $('.form-search .button-search').html("<i class='fa fa-search'></i>");

    if ($(".logo-wrapper").length > 0) {
        $('.logo-wrapper').append("<div class='show-below-960 right nav-search'>" + $(".search-wrapper").html() + "</div>");
    }

    $(window).on('resize', onResize);

    $(document).ready(function () {
        onResize();
    });

    var onResize = function () {
        if ($(window).width() < 960) {
            $(".nav-search .form-search .button.button-search").on("click", function (e) {
                if (!$(".nav-search").hasClass("fly-out")) {
                    e.preventDefault();
                    $(".nav-search").addClass("fly-out");
                    $(".nav-search .form-search .input-text").focus();
                    if (!$('.nav-search .form-search .button.button-close').length) $(".nav-search .form-search .button.button-search").after("<button class='button button-close'><i class='fa fa-times'></i></button>");
                }
            });
        }
    };

    $(document).on('click', '.nav-search .button-close', function (e) {
        e.preventDefault();
        $(".nav-search").removeClass("fly-out");
    });
});


